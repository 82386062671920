import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCopyTime = (props: SVGProps<SVGSVGElement>) => (
  <svg id="Layer_1" viewBox="0 0 24 24" width="1em" height="1em" role="img" {...props}>
    <defs>
      <style>{'.cls-1{fill-rule:evenodd;}'}</style>
    </defs>
    <path
      className="cls-1"
      d="M4.67,4.67a.58.58,0,0,1,.41-.17h7.69a.58.58,0,0,1,.41.17.5.5,0,0,0,.71,0,.51.51,0,0,0,0-.71,1.62,1.62,0,0,0-1.12-.46H5.08A1.57,1.57,0,0,0,3.5,5.08V17A1.59,1.59,0,0,0,5.08,18.6h1v.15a1.59,1.59,0,0,0,1.59,1.58h2.79a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H7.69a.59.59,0,0,1-.42-.17.58.58,0,0,1-.17-.41V6.82a.57.57,0,0,1,.17-.41.6.6,0,0,1,.42-.18h7.68a.58.58,0,0,1,.41.18l2.08,2.07a.58.58,0,0,1,.17.41v.72a.5.5,0,0,0,1,0V8.89a1.62,1.62,0,0,0-.46-1.12L16.49,5.7a1.63,1.63,0,0,0-1.12-.47H7.69a1.57,1.57,0,0,0-1.12.47A1.58,1.58,0,0,0,6.1,6.82V17.6h-1a.63.63,0,0,1-.41-.17A.59.59,0,0,1,4.5,17V5.08A.58.58,0,0,1,4.67,4.67Zm11,6.16a4.83,4.83,0,1,0,3.42,1.41A4.83,4.83,0,0,0,15.66,10.83ZM13,13a3.85,3.85,0,1,1-1.12,2.71A3.82,3.82,0,0,1,13,13Zm3.21.8a.5.5,0,0,0-.5-.5.5.5,0,0,0-.5.5v1.91a.5.5,0,0,0,.5.5h1.92a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H16.16Z"
    />
  </svg>
);
const Memo = memo(SvgIcoCopyTime);
export default Memo;
